.e-grid .e-rowcell:first-child, .e-grid .e-summarycell:first-child{
    padding: 7px 5px !important;
}
.e-grid .e-rowcell {
    padding: 7px 5px !important;
}
.e-grid .e-headercell,
.e-grid .e-detailheadercell {
    padding: 7px 5px !important;
}

.e-grid .e-headercell, .e-grid .e-detailheadercell {
    text-align: center !important;
}

#inputDireita{
    text-align: right !important;
}