@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
html, body {
    font-family: 'Roboto', sans-serif;
    text-rendering: optimizeLegibility;
    font-size: 13.5px;
    background: #e8ecff;

}

body {
    width: 100%;
    height: 100%;
}


.titulo-item-tooltip span{
    font-weight: bold;
}
.login,
.image {
    --input-padding-x: 1.5rem;
    --input-padding-y: 0.75rem;
  min-height: 100vh;
}

.form-login {
  background: #FFF;
}

.bg-image {
  background-image: url(../../static/media/imgLogin.941ac792.png);
  background-size: cover;
  background-position: center;
}

.titulo-login {
  margin-top: 200px;
  margin-left: 50px;
  color: #FFF;

}

.h4-titulo { 
  font-weight: 200;
  margin-bottom: 0px;
}
.h2-titulo { font-weight: 200; }
.h2-titulo strong {  font-weight: 500; }

.rodape-imagem {
  position: absolute;
  bottom: 0;
  font-weight: 200;
  color: #FFF;
  text-align: center;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
  border-radius: 2rem;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:-ms-input-placeholder) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:-ms-input-placeholder)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

#logo-empresa {
  max-width: 100%;
  margin-bottom: 40px;
}
.footer {
    position: relative;
    bottom: 0;
}
.badge-orange {
    background-color: #f37500;
    color: #FFF
}
@media (max-width: 768px){
    .btn-grid{
        margin-top: 5px;
    }
}

.container-card {
    margin-top: 45px;
}

.card-detalhe {
    margin-top:  80px;
    border-radius: 8px;
}

.badge-orange {
    background-color: #f37500;
    color: #FFF
}
.item-card {
    background-color: white;
    color: rgb(12, 133, 214);
    text-align: center;
    border-radius: 5px;
    padding: 25px;
    transition: 300ms;
    height: 100%;
}

.icon-menu {
    font-size: 25px;
    margin-bottom: 10px;
}

.item-card:hover {
    background-color: rgb(12, 133, 214);
    color:white;
    cursor: pointer;
    transition: 300ms;
}

.link-card {
    text-decoration: none !important;
}
.loading-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    padding-top: 40vh;
    background: rgba(0, 0, 0, 0.111);
    text-align: center;
    z-index: 1;
}

.loading-container p {
    color: #007bff;
}
.container-layout {
    margin-top: 47px;
    height: 78vh;
}

.default-footer {
    background-color: red !important;
}
#without-permission-msg {
    width: 100%;
    text-align: center;
}

.titulo-menu {
    color: rgb(12, 133, 214);
}

.subtitulo-menu {
    color: rgb(12, 133, 214);
    font-size: 12px;
}
.body-erro {
    margin: 40px;
    color: rgb(83, 83, 83);
    font-weight: 300;
}

.h1-titulo {
    font-weight: 300;
    margin-bottom: 0;
    padding-bottom: 0;
}

.subtitulo {
    margin-top: 0;
    padding-top: 0;
}
.badge-orange {
    background-color: #f37500;
    color: #FFF
}
.e-grid .e-rowcell:first-child, .e-grid .e-summarycell:first-child{
    padding: 7px 5px !important;
}
.e-grid .e-rowcell {
    padding: 7px 5px !important;
}
.e-grid .e-headercell,
.e-grid .e-detailheadercell {
    padding: 7px 5px !important;
}

.e-grid .e-headercell, .e-grid .e-detailheadercell {
    text-align: center !important;
}

#inputDireita{
    text-align: right !important;
}
body {
    background: #f1f2fa;
}

.rodape {
    position: absolute;
    bottom: 0;
    color: red
}

.table-horiz-scroll {
    overflow-x: auto;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: white;
}


.e-dlg-header, .e-dlg-header * {
    color: inherit;
}

.e-dlg-overlay {
    position: fixed !important;
}

