@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

html, body {
    font-family: 'Roboto', sans-serif;
    text-rendering: optimizeLegibility;
    font-size: 13.5px;
    background: #e8ecff;

}

body {
    width: 100%;
    height: 100%;
}


.titulo-item-tooltip span{
    font-weight: bold;
}